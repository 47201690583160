.search-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 0;
    width: auto;
    /* background-color: white; */
    overflow-x: hidden;
}

.title-container {
    display: flex;
    align-items: center;
    max-width: 70%;
    width: 100%;
    justify-content: space-between;
    margin: 50px 0 30px 0;
    font-size: 48px;

}

.search-component {
    display: flex;
    align-items: center;
    max-width: 70%;
    width: 100%;
    justify-content: space-between;
    margin: 0 30px ;
    z-index: 0;
}


#MBBv3_QuickSearch {
    width: 100%;
}

/* [id^=MBBv3]:not(#mbbV1) [id^=QuickSearchForm].mbb-form-search .bfg-qs-wrapper {
    margin: 0 14% 0 14%;
} */

[id^=MBBv3]:not(#mbbV1) [id^=QuickSearchForm].mbb-form-search .bfg-qs-wrapper {
    max-width: none;
    margin: auto;
}

[id^=MBBv3]:not(#mbbV1) #QuickSearchForm .bfg-qs-wrapper {
    padding: 0 !important;
}

/* Responsive adjustments */

/* Tablets and below */
@media (max-width: 768px) {
    .search-component {
        max-width: 92%;
        width: 100%;
        margin: 0;
    }
    .title-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: flex-start; /* Align items to the left */
        margin: 20px 0 0; /* Reduce margin for smaller screens */
    }

}


/* Mobile phones */
@media (max-width: 480px) {
    .title-container {
        max-width: 100%; /* Use full width on very small screens */
        align-items: flex-start; /* Align items to the left */
        padding-left: 10%;
    }

}
