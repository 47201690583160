.about-me-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Reduced gap for better responsiveness */
    padding: 100px 10%; /* Adjusted padding for better responsiveness */
    background: url('../images/AboutmeBackground.webp') round center;
    background-size: cover;
    z-index: 20;
}

.highlight-blue {
    color: #2980b9;
}

.highlight-orange {
    color: #f39c12;
}

#name {
    font-size: 2.5rem; /* Use rem for better scalability */
    margin-top: 0;
}

#name-2 {
    margin-top: 0;
    font-size: 2.4rem;
    margin-bottom: 1.5%;
}

.profile-image img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    z-index: 1;
}

.profile-details {
    flex: 1;
    z-index: 2;
    padding-right: 5%;
}

.profile-details h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
}

.profile-details p {
    font-size: 1.25rem;
    line-height: 1.5;
    color: #333;
    margin: 5px 0;
}

#contact-p-tag {
    margin-top: 30px;
}

.contact-info {
    display: inline-block;
    background-color: #2c3e50;
    color: white;
    padding: 12px 20px;
    text-decoration: none;
    font-size: 1.375rem;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;

}

.contact-info:hover {
    background-color: #f39c12;
    transform: translateY(-1px);
}

.contact-info-secondary {
    background: #3e5871;
    cursor: pointer;
}

.contact-details {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 10px;
}

.aboutMeSubTitle {
    font-size: 1.5rem;
    color: #2980b9;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .about-me-container {
        flex-direction: column;
        gap: 20px;
        padding: 30px 5%;
        background: url('../images/AboutmeBackground.webp') no-repeat center;
        background-size: cover;
    }

    #profile-name {
        font-size: 1.75rem;
    }

    #profile-name-2 {
        font-size: 1.6rem;
        margin-bottom: 5%;
    }

    .profile-image img {
        width: 200px;
        height: 200px;
        margin-right: 0;
    }

    .profile-details {
        padding-right: 0;
    }

    .profile-details p {
        font-size: 1rem;
    }

    .contact-details {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .contact-info {
        font-size: 1.1rem;
        width: 100%;
        text-align: center;
        width: 100%;
        max-width: 100%;

        box-sizing: border-box;
    }
}
