.back-header-container {
    padding: 0;
    background-color: white;
    position: sticky;
    z-index: 20000;
    top: 0;
    /* background: linear-gradient(to bottom, #333333 75%, rgba(51, 51, 51, 0.8) 100%); */
    display: flex;
    flex-direction: column;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* x-offset, y-offset, blur radius, spread radius, color */
}

.back-button {
    margin: 0;
    padding-left: 10%;
    padding-bottom: 5px;
    background: none;
    border: none;
    outline: 0;
    -webkit-appearance: none; /* For WebKit browsers */
    -moz-appearance: none; /* For Mozilla browsers */
    appearance: none; /* Standard syntax */
    display: inline-block;
    
}

.back-button img {
    padding: 5px 0 0 0;
    height: 30px;
    width: 120%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
    display: flex;
    justify-content: left;
}

.back-button:active {
    transform: scale(0.98);
}

@media (max-width: 768px) {
    .back-button {
        padding-left: 4%;
    }

}


