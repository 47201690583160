#google_translate_element, .goog-te-banner-frame.skiptranslate, .skiptranslate {
  display:none !important;
}


body {
  top: 0 !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd,
.VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-yAWNEb-L7lbkb,
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
    display: none !important;
}

.language-icons {
  display: flex;            /* Arrange items horizontally */
  gap: 15px;                /* Add spacing between icons */
  align-items: center;      /* Vertically align icons */
  margin-right: 15px;
}

.language-icons div {
  cursor: pointer;          /* Make each icon visually clickable */
  margin: 0;
}

.language-icons img {
  width: 30px;              /* Adjust these sizes as necessary */
  height: 30px;
  display: block;
}

.separator {
  margin: 0 20px;
  color: #2c3e50;
  font-size: 1.5em;
}

.header.transparent .logo a, .header.transparent .nav a, .header.transparent .language-text, .header.transparent .separator {
  color: white;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
}
.header.transparent, .entire-header .nav.open.transparent {
  background: none; /* Remove background color when transparent */
  /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8); */
  box-shadow: none;
}

.entire-header, .login-panel, .entire-header * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.entire-header {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3000;
  width: auto;
  
}

.entire-header.no-pointer-events {
  pointer-events: none; /* Disable interaction when header is hidden */
}

.login-panel {
  z-index: 2000;
  /* padding: 0 20px 0 20px; */
  background-color: white;
  /* border: 1px solid red; */
  top: 0;
  position: sticky;
  padding: 0 10%;
  max-height: calc(38px + 5%);
  
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10%;
    /* background: linear-gradient(to bottom, #333333 55%, rgba(51, 51, 51, 0.8) 100%); */
    background-color: white;
    /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8); */
    color: white;
    left: 0;
    right: 0; /* Ensure the header extends to the right edge */
    z-index: 1000; /* Ensure the header is above other content */
    width: 100%;
    transition: transform 0.30s ease;
    transform-origin: top;
    position: sticky;
    top: 0;
    transition: background-color 0.30s ease-in-out, transform 0.30s ease; /* Added transition for background-color */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* x-offset, y-offset, blur radius, spread radius, color */
  }

/* Existing CSS remains unchanged */

  .header.hidden {
    transform: translateY(-100%); /* Move the header out of view */
    visibility: hidden; /* Make the header invisible */
    pointer-events: none; /* Disable interactions with the header */
    transition: transform 0.3s ease, visibility 0.3s ease; /* Smooth transitions */
    
  }

  .logo {
    font-size: 33px;
    font-weight: bold;
    cursor: pointer;
    color: #2c3e50;
  }

  .logo a {
    text-decoration: none;
    color: #2c3e50;
  }
  
  .nav {
    display: flex;
    gap: 25px;
    align-items: center;
  }
  
  .nav a, .language-text, .seperator {
    font-size: 1.2em;
    color: #2c3e50;
    text-decoration: none;
    cursor: pointer;
  }
  
  .nav a:hover, .language-text:hover {
    text-decoration: underline;
    color: #f39c12;
    /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8); */
  }

  .language-icons img:hover {
    transform: translateY(-1px);
  }
 
  
  /* Responsive Styles */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .nav {
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
  
  .logo {
    font-size: 1.5rem; /* Reduce logo size for smaller screens */
    margin-bottom: 10px;
  }
  
  .nav a, .language-text {
    font-size: 1em; /* Reduce font size for smaller screens */
  }

}

.header.open .nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  position: absolute;
  top: 38px; /* Adjust this value to position the menu below the header */
  padding-top: 10px;
  padding-bottom: 15px;
  left: 0;
  right: 0;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  z-index: 1100;
}

.header.open .menu-icon .bar:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.header.open .menu-icon .bar:nth-child(2) {
  opacity: 0;
}

.header.open .menu-icon .bar:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}

.menu-icon {
  display: none; /* Initially hide the burger icon */
  cursor: pointer;
  background: transparent;
  border: none;
  padding-bottom: 12px;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #2c3e50;
  margin: 4px auto;
  transition: all 0.3s ease-in-out;
}

/* Add this CSS to style the burger icon on smaller screens */
@media (max-width: 768px) {
  .header {
    padding-left: 5%;
    padding-right: 5%;
  }
  .login-panel {
    padding: 0 5%;
  }
  .menu-icon {
    display: block;
  }

  .separator {
    display: none;
  }

  .header.transparent .menu-icon .bar {
    background-color: white;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  }

  .nav {
    display: none; /* Initially hide the navigation menu */
  }

  .language-icons img {
    width: 40px;
    height: 40px;
  }

  [id^=MBBv3]:not(#mbbV1) .elq-572 mbb-login-panel .btn-toolbar .bfg-row-2, [id^=MBBv3]:not(#mbbV1).elq-572 mbb-login-panel .btn-toolbar .bfg-row-2 {
    display: none !important;
  }

  [id^=MBBv3]:not(#mbbV1) mbb-login-panel .btn-toolbar .bfg-row-2 {
    display: none !important;
    flex: 1 0 auto;
    order: 2;
  }

  [id^=MBBv3]:not(#mbbV1) mbb-login-panel .btn-toolbar .bfg-row-2 {
    display: none !important;
  }

  [id^=MBBv3]:not(#mbbV1) .elq-768 mbb-login-panel .btn-toolbar .bfg-row-2 div+div, [id^=MBBv3]:not(#mbbV1).elq-768 mbb-login-panel .btn-toolbar .bfg-row-2 div+div {
    display: none;
  }

  .bfg-row-2 btn-group ml-0 {
    display: none !important;
  }
}