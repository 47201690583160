.flex-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto; /* This will make it take the full viewport height */
    padding: 0;
    /* background-color: white; */
}

.header-container {
    display: flex;
    align-items: center;
    max-width: 70%; /* This should be the width of your grid container */
    width: 100%;
    justify-content: space-between; /* This pushes the h1 and button to the edges */
    margin: 50px 0 0 0;
}

h1 {
    font-size: 48px;
    margin: 0; 
    /* flex-grow: 1;  */
    color: #333;
}

#HomepageTitle{
  font-size: 48px;
  margin: 0;
}

.view-all-btn {
    cursor: pointer;
    background-color: white;
    color: black;
    padding: 14px 24px;
    border: 1px solid black;
    font-weight: bold;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    width: 70%;
    margin: 30px;
  }
  
  .grid-box::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 35%; /* covers the bottom 25% of the grid box */
    background: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1)); /* Gradient effect */
    z-index: -1;
    }

  .grid-box {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 250px;
    min-height: 120px;
    border: none;
    padding: 16px;
    font-size: 24px;
    background-color: #f7f7f7;
    background-size: cover; /* This ensures the image covers the entire grid box */
    background-position: center; /* This centers the image in the grid box */
    color: white; /* Adjust text color if needed */
    position: relative;
    z-index: 0;
    text-decoration: none;
    font-weight: bold;
  }
  

  .grid-box:hover {
    transform: translateY(-2px);
    color: #f39c12;
}

  /* For medium-sized screens: 2 boxes per row */
  @media (max-width: 800px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* For smaller screens: 1 box per row */
  @media (max-width: 500px) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

/* For medium-sized screens */
@media (max-width: 800px) {
    .header-container {
        flex-direction: column; /* Stack the H1 and button vertically */
        align-items: flex-start; /* Align items to the start (left) */
        gap: 10px; /* space between the H1 and button */
    }

    h1 {
        font-size: 24px; /* Adjust the font size */
    }

    .view-all-btn {
        padding: 8px 16px; /* Adjust padding */
        font-size: 14px; /* Adjust the font size */
    }
}

/* For smaller screens */
@media (max-width: 500px) {
    .header-container {
        max-width: 90%; /* Give a bit more width for better readability */
        margin: 30px 0 0;
    }

    .grid-container {
        width: 90%; /* Adjust the width to match the header container */
        margin: 20px 0; /* Reduce the margin */
    }

    h1 {
        font-size: 20px; /* Further reduce the font size */
    }

    .view-all-btn {
        padding: 6px 12px; /* Further reduce padding */
        font-size: 12px; /* Further reduce the font size */
    }

    .grid-box {
        font-size: 1em; /* Adjust the font size for the grid box */
        padding: 10px; /* Adjust padding */
    }
}
