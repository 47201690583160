h1.title:hover {
    color: #f39c12;
    cursor: pointer;
}

.palmBeachContainer {
    background-color: #edecec;
}

.bannerImage img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    box-shadow: 0 10px 8px rgba(0, 0, 0, 0.2);
}

.bannerImage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.bannerContent {
    position: absolute;
    text-align: center;
    color: white;
    margin-top: 12%;
}

p.description {
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.8);
    font-size: 22px;
    color: white;
}

.flex-wrapper-locations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    margin: 0;
    margin-top: 80px;
}

.bannerContent a {
    text-decoration: none;
}

h1.title {
    font-size: 48px;
    margin: 0;
    color: white;
    text-decoration: none;
    text-shadow: 4px 2px 1px rgba(0, 0, 0, 0.8);
}

.introduction, .facts-grid, .conclusion {
    width: 70%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
}

.introduction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 51px;
}

.intro-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* Updated content styles */
.content-container {
    display: flex;
    flex-direction: column;
    margin: 20px 10%;
}

.content-title h1 {
    font-size: 37px;
}

.content-title {
    text-align: left;
    margin: 30px 0 20px 0;
}

.content-title.reverse {
    text-align: right;
}

.content-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 50px;
}

.content-body.reverse .content-description {
    order: 2;
}

.content-body.reverse .content-img {
    order: 1;
    justify-content: flex-start;
}

.content-description, .content-img {
    flex: 1;
    margin: 0;
    padding: 0;
}

.description-title {
    margin: 0;
    font-size: 28px;
}

.description-tag {
    margin-top: 0;
    font-size: 20px;
}

.content-img {
    display: flex;
}

.content-img img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
}

/* Facts grid centered with 3 facts per row */
.facts-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 0;
    max-width: 1500px;
}

.fact-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    height: 282px;
    min-height: 120px;
    border: none;
    padding: 16px;
    font-size: 1.6em;
    background-color: lightblue;
    background-size: cover;
    background-position: center;
    color: white;
    position: relative;
    border-radius: 0;
    z-index: 0;
}

.fact-card::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 35%;
    background: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
    z-index: -1;
}

.fact-card:hover {
    transform: translateY(-5px);
    color: #f39c12;
    cursor: pointer;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.8);
}

.fact-heading {
    font-size: 22px;
    margin-bottom: 0;
}

.fact-description {
    margin-top: 2%;
    font-size: 18px;
    margin-bottom: 0;
}

.spacer {
    flex-grow: 1;
}

.extra-facts {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-bottom: 2%;
}

.search-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.search {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 0;
    padding: 20px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    background-color: #2c3e50;
    text-align: center;
    margin: 0 auto;
    width: calc(100% - 40px); /* Full width minus padding */
    max-width: 600px; /* Maximum width for large screens */
    margin-top: 2%;
    margin-bottom: 3%;
    font-size: 30px;
    font-weight: 700;
}

.search a {
    color: white;
    text-decoration: none;
    width: 100%;
    text-align: center;
}


.search:hover {
    background-color: #f39c12;
    transform: translateY(-2px);
}

.closing {
    font-size: 24px;
    margin-bottom: 0;
}

/* For medium-sized screens: 2 boxes per row */
@media (max-width: 800px) {
    .facts-grid  {
      grid-template-columns: repeat(2, 1fr);
    }
}
  
/* For smaller screens: 1 box per row */
@media (max-width: 500px) {
    .facts-grid  {
      grid-template-columns: repeat(1, 1fr);
    }
}

/* For medium-sized screens */
@media (max-width: 800px) {
    .introduction {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    h1 {
        font-size: 24px;
    }
}

/* For smaller screens */
@media (max-width: 500px) {
    .introduction {
        max-width: 90%;
    }

    .facts-grid {
        width: 90%;
        margin: 20px 0;
    }

    h1 {
        font-size: 20px;
    }

    .fact-card {
        font-size: 1em;
        padding: 10px;
    }
}

@media (max-width: 768px) {

    .conclusion {
        margin: 0;
        margin-bottom: 5%;
        width: 90%;
        
    }

    .search {
        padding: 15px; /* Adjust padding for smaller screens */
        font-size: 20px; /* Adjust font size for smaller screens */
        max-width: 70%;
    }

    .content-container {
        margin: 20px 20px 0 20px;
    }

    .content-title h1 {
        font-size: 30px;
    }

    .content-title {
        margin-top: 20px;
    }

    .description-title {
        font-size: 25px;
    }

    .description-tag {
        font-size: 24px;
    }

    .content-title.reverse {
        text-align: left;
    }

    .content-body {
        flex-direction: column;
        gap: 20px;
    }

    .content-body .content-img,
    .content-body.reverse .content-img {
        order: 3;
    }

    .content-body .content-description {
        order: 2;
    }
}
