.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url('../images/AboutmeBackground.webp') no-repeat center center;
    background-size: cover; /* Ensures the background image covers the entire area */
    color: #ffffff;
    text-align: center;
  }
  
  .error-title {
    font-size: 6rem;
    font-weight: bold;
    color: #2c3e50; /* Highlighted error color */
    margin-bottom: 20px; /* Adds space between the title and the message */
  }
  
  .error-message {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .error-link {
    text-decoration: none;
    color: #2c3e50;
    font-size: 1.2rem;
    padding: 10px 20px;
    border: 2px solid #2c3e50;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .error-link:hover {
    background-color: #f39c12;
    color: #ffffff;
  }
  